import { Backdrop, Container, Button,
         Snackbar, Slide, FormControl, 
         Grid, InputLabel, OutlinedInput, CircularProgress } from '@material-ui/core'
import { PersonOutline } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import Axios from 'axios'
import React, { Component } from 'react'
import { getLocalUser, host, waitBackDrop } from '../../assets/constants'
import DialogTitle from '../../central/DialogTitle'

export default class ChangePassword extends Component {
  state = {
    open: true,
    passOld: "",
    passNew: "",
    user: null,
    message: null,
    severity: "error",
    error:{},
    wait: false
  }

  componentDidMount(){
    const user = getLocalUser()
    this.setState({user})
    //console.log(user)
  }

  sendNewPassword = () => {
    const {user, passOld:sa, passNew:sn} = this.state
    
    if(sa.length===0){
        this.setState({message:"Informe a senha atual!", error:{passOld:true}})
        return
    }

    if(sn.length<8){
        this.setState({message:"Informe a nova senha com pelo menos 8 caracteres!", error:{passNew:true}})
        return
    }

    if(sn === sa){
        this.setState({message:"As senhas são iguais", error:{passNew:true, passOld:true}})
        return
    }

    this.setState({wait:true, message:null, error:{}}, async ()=>{
        Axios
            .post(`${host}/users/change/password`,{id:user._id, sa, sn})
            .then(({data})=>{
                this.setState({message: data.msg, severity: "success", wait:false})
            })
            .catch(({response}) =>{ 
                // if (response && response.status === 401) { message="Confira seu usuário/senha." }
                this.setState({message: response.data.msg || response.data.message, wait:false, error:{passOld:true}})
            })
    })
        
  }

  closeForm = () => { 
    this.props.onClose()
  }

  handleInputchange = e => {
    const {name, value} = e.target;
    this.setState({[name]:value})
    //console.log(name,value)
 }

 clearMessage = () => {
    const {severity} = this.state
    this.setState({message:"", severity:"error"}, ()=>{
        if(severity==="success")
            this.closeForm()
    })
 }

 mySlide = (props) => <Slide {...props} direction="down"><Alert severity={this.state.severity} onClose={this.clearMessage}>{this.state.message}</Alert></Slide>
    
 render() {
    const {open, passOld, passNew, user, message, error, wait, severity} = this.state
    
    if(!open || !user)
        return <></>
//onClick={()=>{this.setState({open:false})}}
    return (
     <> 
        {
          wait ? waitBackDrop(wait) :
            <Backdrop open={true} className="app-loading" >
                <Container maxWidth="sm" style={{backgroundColor:"#FFFFFFdd", borderRadius:"5px", display: severity==="success" ? 'none' : null}}>
                    <DialogTitle text1="ALTERAÇÃO" text2="DE SENHA" icon={<PersonOutline />}/>
                    
                    <form noValidate className="app-form app-form-login" style={{maxWidth:'none'}}>
                     <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="passOld">Senha Atual</InputLabel>
                                <OutlinedInput
                                    error={error.passOld}
                                    fullWidth
                                    type='text'
                                    autoFocus={true}
                                    id="passOld"
                                    name="passOld"
                                    value={passOld}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="passNew">Nova Senha</InputLabel>
                                <OutlinedInput
                                    error={error.passNew}
                                    fullWidth
                                    type='text'
                                    // autoFocus={mail.length ? true : false}
                                    id="passNew"
                                    name="passNew"
                                    value={passNew}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                     </Grid>
                    </form>

                    <Grid container spacing={1} style={{padding:"1em 0"}}>
                        <Grid item xs={6}>
                            <Button type="button" fullWidth className="app-form-send" onClick={this.sendNewPassword}>ALTERAR</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                type="button" 
                                style={{width:'100%', margin:0}} 
                                className="app-form-send app-form-ins-cancel" 
                                onClick={this.closeForm}
                            >CANCELAR</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Backdrop>
        }
        
        <Snackbar
            open={message != null && message.length>0} 
            autoHideDuration={4000} 
            onClose={this.clearMessage}
            TransitionComponent={this.mySlide}
        />
     </>
    )
  }
}
