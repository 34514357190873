import { Backdrop, Container, Button,
         Snackbar, Slide, FormControl, 
         Grid, InputLabel, OutlinedInput, CircularProgress } from '@material-ui/core'
import { PersonOutline } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import Axios from 'axios'
import React, { Component } from 'react'
import { decodeToken } from '../../assets/auth'
import { getLocalUser, host, waitBackDrop } from '../../assets/constants'
import DialogTitle from '../../central/DialogTitle'

export default class ChangeUserData extends Component {
  state = {
    open: true,
    firstname: "",
    lastname: "",
    password:"",
    user: null,
    message: null,
    severity: "error",
    error:{},
    wait: false
  }

  componentDidMount(){
    const user = getLocalUser()
    this.setState({user, firstname:user.nome, lastname:user.sobre})
    //console.log(user)
  }

  sendData = () => {
    const {user, firstname:nome, lastname:sobre, password:pass} = this.state
    
    if(nome.trim().length===0){
        this.setState({message:"Informe o primeiro nome!", error:{firstname:true}})
        return
    }

    if(sobre.trim().length===0){
        this.setState({message:"Informe o sobrenome!", error:{lastname:true}})
        return
    }

    if(pass.length===0){
        this.setState({message:"Informe a senha de acesso!", error:{password:true}})
        return
    }

    this.setState({wait:true, message:null, error:{}}, async ()=>{
        Axios
            .post(`${host}/users/change/data`,{id:user._id, pass, fields:{nome, sobre}})
            .then(({data})=>{
                this.setState({message: data.msg, severity: "success", wait:false}, ()=>{
                    localStorage.setItem("user", decodeToken(JSON.stringify({...user, nome, sobre})))
                    setTimeout(()=>{
                        window.location.reload()
                    }, 4000)
                })
            })
            .catch(({response}) =>{ 
                // if (response && response.status === 401) { message="Confira seu usuário/senha." }
                this.setState({message: response.data.msg || response.data.message, wait:false, error:{passOld:true}})
            })
    })
        
  }

  closeForm = () => { 
    this.props.onClose()
  }

  handleInputchange = e => {
    const {name, value} = e.target;
    this.setState({[name]:value})
    //console.log(name,value)
 }

 clearMessage = () => {
    const {severity} = this.state
    this.setState({message:"", severity:"error"}, ()=>{
        if(severity==="success")
            this.closeForm()
    })
 }

 mySlide = (props) => <Slide {...props} direction="down"><Alert severity={this.state.severity} onClose={this.clearMessage}>{this.state.message}</Alert></Slide>
    
 render() {
    const {open, firstname, lastname, password, user, message, error, wait, severity} = this.state
    
    if(!open || !user)
        return <></>
//onClick={()=>{this.setState({open:false})}}
    return (
     <> 
        {
          wait ? waitBackDrop(wait) :
            <Backdrop open={true} className="app-loading" >
                <Container maxWidth="sm" style={{backgroundColor:"#FFFFFFdd", borderRadius:"5px", display: severity==="success" ? 'none' : null}}>
                    
                    <DialogTitle text1="ALTERAÇÃO" text2="DE NOME" icon={<PersonOutline />} />
                    
                    <form noValidate className="app-form app-form-login" style={{maxWidth:'none'}}>
                     <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="firstname">Primeiro Nome</InputLabel>
                                <OutlinedInput
                                    error={error.firstname}
                                    fullWidth
                                    type='text'
                                    autoFocus={true}
                                    id="firstname"
                                    name="firstname"
                                    value={firstname}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="lastname">Sobrenome</InputLabel>
                                <OutlinedInput
                                    error={error.lastname}
                                    fullWidth
                                    type='text'
                                    // autoFocus={mail.length ? true : false}
                                    id="lastname"
                                    name="lastname"
                                    value={lastname}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="password">Senha de Acesso</InputLabel>
                                <OutlinedInput
                                    error={error.password}
                                    fullWidth
                                    type='password'
                                    // autoFocus={mail.length ? true : false}
                                    id="password"
                                    name="password"
                                    value={password}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                     </Grid>
                    </form>

                    <Grid container spacing={1} style={{padding:"1em 0"}}>
                        <Grid item xs={6}>
                            <Button type="button" fullWidth className="app-form-send" onClick={this.sendData}>ALTERAR</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                type="button" 
                                style={{width:'100%', margin:0}} 
                                className="app-form-send app-form-ins-cancel" 
                                onClick={this.closeForm}
                            >CANCELAR</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Backdrop>
        }
        
        <Snackbar
            open={message != null && message.length>0} 
            autoHideDuration={4000} 
            onClose={this.clearMessage}
            TransitionComponent={this.mySlide}
        />
     </>
    )
  }
}
