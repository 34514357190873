import React        from 'react'
import Axios    from 'axios'
import {
    AppBar,
    Toolbar,
    IconButton, 
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    Collapse}         from '@material-ui/core'

import {
    Share,
    MailOutlined,
    ExpandLess,
    ExpandMore,
    ExitToApp,
    AccountBoxOutlined,
    LockOutlined,
    MoreHoriz, 
    PersonOutline,
    StoreOutlined,
    ViewHeadline,
    PhoneAndroidOutlined,
    AlternateEmailOutlined,
    Settings,
    DynamicFeed,
    SettingsPower}     from '@material-ui/icons'

import
    ElevationScroll from './ElevationScroll'

import logo         from '../assets/imgs/logo_white.png'
import { getLocalShop, myServer, staticServer } from '../assets/constants'
import { getMat, isMobile }   from '../assets/functions'
import ChangePassword from './user/ChangePassword'
import ChangeUserData from './user/ChangeUserData'
import ContactMessage from '../central/menu/ContactMessage'
import ChangeShopEmails from './shop/ChangeShopEmails'
import ChangeShopPhones from './shop/ChangeShopPhones'
import ChangeAdObs from './shop/ChangeAdObs'

export default class Head extends React.Component {
    state = {
        user: {},
        showMenu : false,
        menuOptions: [
            // {icon:<ShareOutlined />,    desc:"Integrador",      link:"/", ext: true},
            // {icon:<StoreOutlined />,    desc:"Dados da Revenda",link:"/", ext: true},
            // {icon:<ReceiptOutlined />,  desc:"Políticas",       link: staticServer+"/politica/privacidade", ext: true},
            {icon:<MailOutlined />,     desc:"Contato",         link:"#",       ext: false, onClick:()=>{this.setState({alert: <ContactMessage onClose={()=>{this.setState({alert:null})}}/>})} },
            {icon:<ExitToApp />,        desc:"Sair",            link:`/sair`,   ext:false},
        ],
        submenu:{},
        alert: null,
        shopId: null
    }

    toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
            return
        
        this.setState({ showMenu: open });
    }

    getUserSession = () => {
        const {user} = this.props;
        if(user){
            this.setState({user})
        }
    }

    componentDidMount(){
        //this.getUserSession()
    }
    
    static getDerivedStateFromProps(nextProps, prevProps){
        const { user , shops}  = nextProps;
        
        return user && user.nome !== prevProps.user.nome ? {user, shops} : null
    }

    setSubmenu = (menu) => (e) => {
        const op = menu.submenu
        op ? this.setState((s)=>({submenu:{[op]: s.submenu[op] ? !s.submenu[op] : true}})) : this.runMenuAction(menu)(e)
    }

    runMenuAction =  (item) => (e) => {
        const {onClick} = item || {onClick:()=>{}}
        this.setState({ showMenu: false }, onClick);
    }

    sideList = () => {
        const {menuOptions, user, submenu} = this.state,
            shop = getLocalShop();

        let Options = []

        if(user && user.nome)
            Options.push({icon:<PersonOutline />, desc:user.nome, link:"#", ext: false, submenu: "user",
                            options:[  {icon:<LockOutlined />, desc:"Alterar Senha", onClick:()=>{this.setState({alert: <ChangePassword onClose={()=>{this.setState({alert:null})}}/>})}}, 
                                    {icon:<AccountBoxOutlined />, desc:"Alterar Nome", onClick:()=>{this.setState({alert: <ChangeUserData onClose={()=>{this.setState({alert:null})}}/>})}}]
                        })

        if(shop){
            Options.push({icon:<StoreOutlined />, desc:"Revenda", link:"#", ext: false, submenu: "shop",
                        options:[  {icon:<PhoneAndroidOutlined />, desc:"Telefones", onClick:()=>{this.setState({alert: <ChangeShopPhones onClose={()=>{this.setState({alert:null})}}/>})}}, 
                                    {icon:<AlternateEmailOutlined />, desc:"E-mails", onClick:()=>{this.setState({alert: <ChangeShopEmails onClose={()=>{this.setState({alert:null})}}/>})}}, 
                                    {icon:<ViewHeadline />, desc:"Observações", onClick:()=>{this.setState({alert: <ChangeAdObs onClose={()=>{this.setState({alert:null})}}/>})}}]
                        })
            /*if(shop.integracao){
                if(shop.integracao.codigo){
                    Options.push({icon:<Share />, desc:"integração", link:"#", ext: false, submenu: "integration",
                                options:[  //{icon:<Settings />, desc:"Configuração", onClick:()=>{ }}, 
                                        {icon:<DynamicFeed />, desc:"Em Lote", onClick:()=>{ window.location.href=`/anuncios/${shop._id}/integracao/lote/` }}]
                            })
                }else{
                    // Options.push({icon:<Share />, desc:"integração", link:"#", ext: false, submenu: "integration",
                    //             options:[  {icon:<SettingsPower />, desc:"Ativar", onClick:()=>{ }}]
                    //         })
                }

            } */
        }

        menuOptions.forEach(m => Options.push(m))
        Options = Options.filter(e => user && user._id ? e.ext===false : e.ext===true)

        // Options = [{icon:<PersonOutline />, desc:user.nome, link:"#", ext: false, submenu: "user",
        //              options:[  {icon:<LockOutlined />, desc:"Alterar Senha", onClick:()=>{this.setState({alert: <ChangePassword onClose={()=>{this.setState({alert:null})}}/>})}}, 
        //                         {icon:<AccountBoxOutlined />, desc:"Alterar Nome", onClick:()=>{this.setState({alert: <ChangeUserData onClose={()=>{this.setState({alert:null})}}/>})}}]
        //             },
        //             {icon:<StoreOutlined />, desc:"Revenda", link:"#", ext: false, submenu: "shop",
        //              options:[  {icon:<PhoneAndroidOutlined />, desc:"Telefones", onClick:()=>{this.setState({alert: <ChangeShopPhones onClose={()=>{this.setState({alert:null})}}/>})}}, 
        //                         {icon:<AlternateEmailOutlined />, desc:"E-mails", onClick:()=>{this.setState({alert: <ChangeShopEmails onClose={()=>{this.setState({alert:null})}}/>})}}, 
        //                         {icon:<ViewHeadline />, desc:"Observações", onClick:()=>{this.setState({alert: <ChangeAdObs onClose={()=>{this.setState({alert:null})}}/>})}}]
        //             },
        //             {icon:<Share />, desc:"integração", link:"#", ext: false, submenu: "integration",
        //             options:[  {icon:<Settings />, desc:"Configuração", onClick:()=>{ }}, 
        //                        {icon:<DynamicFeed />, desc:"Em Lote", onClick:()=>{ }}]
        //             }
        //             ,...Options]
        //             .filter(e => user && user._id ? e.ext===false : e.ext===true);

        return(
            <div
                style={{width:"220px"}}
                role="presentation"
                //onClick={this.toggleDrawer(false)}
                onKeyDown={this.toggleDrawer(false)}
            >
                <List>
                    {Options //.filter(e => user && user._id ? e.ext===false : e.ext===true)
                     .map((menu, index) => (
                        <a href={menu.submenu ? null : menu.link} key={index} >
                            <ListItem button onClick={this.setSubmenu(menu)}>
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                <ListItemText primary={menu.desc} />
                                {menu.submenu ? ( submenu[menu.submenu] ? <ExpandLess /> : <ExpandMore />) : <></>}
                            </ListItem>
                            {
                                menu.options ? 
                                    <Collapse in={submenu[menu.submenu] ?? false} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                        {  menu.options.map((o, i)=>(
                                            <ListItem button key={i} onClick={this.runMenuAction(o)}>
                                                <ListItemIcon style={{paddingLeft:13}}>{o.icon}</ListItemIcon>
                                                <ListItemText primary={o.desc} />
                                            </ListItem>
                                        )) }
                                        </List>
                                    </Collapse>
                                : <></>
                            }
                        </a>
                    ))}
                </List>
            </div>
        )
    }

    handleUserClick = () =>{
        const {user} = this.state
        
        if(user && user.nome) window.location.href = myServer + (isMobile() ? '/mobile/entrar' : '/anuncio/listar')
        else                  window.location.href = myServer + (isMobile() ? '/mobile/entrar' : '/entrar/?op=l') 
    }

    render() {
        const {showMenu, user, alert} = this.state,
            central = window.location.pathname.indexOf('/central/')===0,
            hideMenu = isMobile() && window.location.search.indexOf("menu=off")>0;
        //console.log(hideMenu)
        return (
            <div > 
                {alert}
                <ElevationScroll {...this.props}>
                    <AppBar position="static" className="app-nav-top" style={{zIndex:998}}> 
                        <Toolbar>
                            <div className="app-nav-logo">
                                <a href="/"><img src={logo} alt="bomdapeste logomarca" style={{float: hideMenu ? "right" : "none"}}/></a>
                            </div>
                            {/* <IconButton className={"app-nav-login"+(central ? " d-none" : "")} onClick={this.handleUserClick} style={{display: hideMenu ? "none" : null}}>
                                <Avatar alt="Login" src="/imgs/cacto-mini-verde.png" className="app-nav-login-img" />
                                <span className="app-user-nome app-ellipsis">{user && user.nome ? user.nome : "Entrar"}</span>
                            </IconButton> */}
                            <IconButton
                             edge="start"
                             color="inherit" 
                             aria-label="menu" 
                             className="app-nav-menu" 
                             onClick={this.toggleDrawer(true)} 
                             style={{display: hideMenu ? "none" : (user && user.nome ? null : 'none')}}
                            //  style={{display: hideMenu ? "none" : null}}
                            >
                                <MoreHoriz />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <Drawer anchor="right" open={showMenu} onClose={this.toggleDrawer(false)}>
                    {this.sideList()}
                </Drawer>
            </div>
        )
    }
}


