import { Backdrop, Container, Button,
         Snackbar, Slide, FormControl, 
         Grid, InputLabel, OutlinedInput } from '@material-ui/core'
import { StoreOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import Axios from 'axios'
import React, { Component } from 'react'
import { decodeToken } from '../../assets/auth'
import { getLocalShops, getLocalToken, getLocalUser, host, waitBackDrop } from '../../assets/constants'
import { isValidEmail } from '../../assets/functions'
import DialogTitle from '../../central/DialogTitle'

export default class ChangeShopEmails extends Component {
  state = {
    open: true,
    proposta: "",
    contato: "",
    password:"",
    user: null,
    message: null,
    severity: "error",
    error:{},
    wait: false
  }

  componentDidMount(){
    const
        user    = getLocalUser(),
        shopId  = localStorage.getItem("shopId"),
        shop    = getLocalShops("shops").filter(s => s._id===shopId),
        {proposta, contato} =  shop[0];

    this.setState({user, proposta, contato, shopId})
    // console.log(shop[0])
  }

  sendData = () => {
    const {user, proposta, contato, password:pass, shopId:id} = this.state,
        {mail} = user;
    
    if(proposta.trim().length===0 || !isValidEmail(proposta)){
        this.setState({message:"Informe um e-mail para contato válido!", error:{proposta:true}})
        return
    }

    if(contato && contato.trim().length>0 && !isValidEmail(contato)){
        this.setState({message:"Informe um e-mail financeiro válido!", error:{contato:true}})
        return
    }

    if(pass.length===0){
        this.setState({message:"Informe a senha de acesso!", error:{password:true}})
        return
    }

    this.setState({wait:true, message:null, error:{}}, async ()=>{
        const token = getLocalToken()
        Axios
            .put(`${host}/shops/${id}/update`,{proposta, contato},{headers:{'x-access-token': token, data: JSON.stringify({mail, pass})}})
            .then(({data})=>{
                if(data && data.success){
                    this.setState({message: "Atualização Efetuada!", severity: "success", wait:false}, ()=>{
                        let shops = getLocalShops("shops")
                        shops.forEach((s,i) => {
                            if(s._id===id){
                                shops[i]={...s, proposta, contato}
                            }
                        });
                        
                        localStorage.setItem("shops", decodeToken(JSON.stringify(shops)))
                    })
                }else{
                    this.setState({message: data.message, wait:false, error:{passOld:true}})
                }
            })
            .catch(({response}) =>{ 
                // if (response && response.status === 401) { message="Confira seu usuário/senha." }
                this.setState({message: response.data.msg || response.data.message, wait:false, error:{passOld:true}})
            })
    })
        
  }

  closeForm = () => { 
    this.props.onClose()
  }

  handleInputchange = e => {
    const {name, value} = e.target;
    this.setState({[name]:value})
    //console.log(name,value)
 }

 clearMessage = () => {
    const {severity} = this.state
    this.setState({message:"", severity:"error"}, ()=>{
        if(severity==="success")
            this.closeForm()
    })
 }

 mySlide = (props) => <Slide {...props} direction="down"><Alert severity={this.state.severity} onClose={this.clearMessage}>{this.state.message}</Alert></Slide>
    
 render() {
    const {open, proposta, contato, password, user, message, error, wait, severity} = this.state
    
    if(!open || !user)
        return <></>
//onClick={()=>{this.setState({open:false})}}
    return (
     <> 
        {
          wait ? waitBackDrop(wait) :
            <Backdrop open={true} className="app-loading" >
                <Container maxWidth="sm" style={{backgroundColor:"#FFFFFFdd", borderRadius:"5px", display: severity==="success" ? 'none' : null}}>
                    
                    <DialogTitle text1="ALTERAÇÃO" text2="DE E-MAIL" icon={<StoreOutlined />} />
                    
                    <div noValidate className="app-form app-form-login" style={{maxWidth:'none'}}>
                     <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="firstname">E-mail Comercial</InputLabel>
                                <OutlinedInput
                                    error={error.proposta}
                                    fullWidth
                                    type='text'
                                    autoFocus={true}
                                    id="proposta"
                                    name="proposta"
                                    value={proposta}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="lastname">E-mail Financeiro</InputLabel>
                                <OutlinedInput
                                    error={error.contato}
                                    fullWidth
                                    type='text'
                                    // autoFocus={mail.length ? true : false}
                                    id="contato"
                                    name="contato"
                                    value={contato}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className="" variant="filled" fullWidth>
                                <InputLabel htmlFor="password">Senha de Acesso</InputLabel>
                                <OutlinedInput
                                    error={error.password}
                                    fullWidth
                                    type='password'
                                    // autoFocus={mail.length ? true : false}
                                    id="password"
                                    name="password"
                                    value={password}
                                    className  ="app-form-input"
                                    labelWidth={70}
                                    onChange={this.handleInputchange}
                                />
                            </FormControl>
                        </Grid>
                     </Grid>
                    </div>

                    <Grid container spacing={1} style={{padding:"1em 0"}}>
                        <Grid item xs={6}>
                            <Button type="button" fullWidth className="app-form-send" onClick={this.sendData}>ALTERAR</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                type="button" 
                                style={{width:'100%', margin:0}} 
                                className="app-form-send app-form-ins-cancel" 
                                onClick={this.closeForm}
                            >CANCELAR</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Backdrop>
        }
        
        <Snackbar
            open={message != null && message.length>0} 
            autoHideDuration={4000} 
            onClose={this.clearMessage}
            TransitionComponent={this.mySlide}
        />
     </>
    )
  }
}
