import React, { Component } from 'react'
import { Backdrop, Container, Button,
    Snackbar, Slide, FormControl, 
    Grid, InputLabel, OutlinedInput } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Axios from 'axios'
import NumberFormat from 'react-number-format'

import { getLocalShops, getLocalToken, getLocalUser, host, waitBackDrop } from '../../assets/constants'
import DialogTitle from '../../central/DialogTitle'
import { decodeToken } from '../../assets/auth'
import { StoreOutlined } from '@material-ui/icons'


export default class ChangeAdObs extends Component {
    state = {
        open: true,
        obs: "",
        password: "",
        shopId:"",
        message: null,
        severity: "error",
        error:{},
        wait: false
      }
    
    componentDidMount(){
        const user = getLocalUser(),
            shopId  = localStorage.getItem("shopId"),
            shop    = getLocalShops("shops").filter(s => s._id===shopId),
            {obs} =  shop[0];
            
        this.setState({user, shopId, obs:(obs??"").replace(/(<br>|<br\/>)/gi,'\n')})
        
      }
    
    sendData = () => {
        let {user, obs, password:pass, shopId:id} = this.state,
            {mail} = user;

        obs = obs.replace(/\n/g,'<br>')
    
        if(pass.length===0){
            this.setState({message:"Informe a senha de acesso!", error:{password:true}})
            return
        }

        this.setState({wait:true, message:null, error:{}}, async ()=>{
            const token = getLocalToken()
            Axios
                .put(`${host}/shops/${id}/update`,{obs},{headers:{'x-access-token': token, data: JSON.stringify({mail, pass})}})
                .then(({data})=>{
                    if(data && data.success){
                        this.setState({message: "Atualização Efetuada!", severity: "success", wait:false}, ()=>{
                            let shops = getLocalShops("shops")
                            shops.forEach((s,i) => {
                                if(s._id===id){
                                    shops[i]={...s, obs}
                                }
                            });
                            
                            localStorage.setItem("shops", decodeToken(JSON.stringify(shops)))
                        })
                    }else{
                        this.setState({message: data.message, wait:false, error:{passOld:true}})
                    }
                })
                .catch(({response}) =>{ 
                    // if (response && response.status === 401) { message="Confira seu usuário/senha." }
                    this.setState({message: response.data.msg || response.data.message, wait:false, error:{passOld:true}})
                })
        })
            
      }
    
    closeForm = () => { 
        this.props.onClose()
      }
    
    handleInputchange = e => {
        let {name, value} = e.target;
        
        this.setState({[name]:value})
     }
    
    clearMessage = () => {
        const {severity} = this.state
        this.setState({message:"", severity:"error"}, ()=>{
            if(severity==="success")
                this.closeForm()
        })
     }
    
    mySlide = (props) => <Slide {...props} direction="down"><Alert severity={this.state.severity} onClose={this.clearMessage}>{this.state.message}</Alert></Slide>
     

    TextMaskPhone = (props) => { 
        const { inputRef, ...other } = props;
        return  <NumberFormat format={"(##) # ####-####"} {...other}/> 
    }
    render() {
        const {open, obs, password, user, message, error, wait, severity} = this.state
        
        if(!open || !user)
            return <></>
            
        return (
         <> 
            {
              wait ? waitBackDrop(wait) :
                <Backdrop open={true} className="app-loading" >
                    <Container maxWidth="sm" style={{backgroundColor:"#FFFFFFdd", borderRadius:"5px", display: severity==="success" ? 'none' : null}}>
                        <DialogTitle text1="ALTERAR" text2="OBSERVAÇÕES" icon={<StoreOutlined />} />
                        
                        <div noValidate className="app-form app-form-login" style={{maxWidth:'none'}}>
                         <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <FormControl className="" variant="filled" fullWidth>
                                    <InputLabel htmlFor="passOld">Texto</InputLabel>
                                    <OutlinedInput
                                        // error={error.obs}
                                        fullWidth
                                        type='text'
                                        autoFocus={true}
                                        id="obs"
                                        name="obs"
                                        value={obs}
                                        className  ="app-form-input"
                                        labelWidth={70}
                                        onChange={this.handleInputchange}
                                        multiline={true}
                                    />
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <FormControl className="" variant="filled" fullWidth>
                                    <InputLabel htmlFor="password">Senha de Acesso</InputLabel>
                                    <OutlinedInput
                                        error={error.password}
                                        fullWidth
                                        type='password'
                                        // autoFocus={mail.length ? true : false}
                                        id="password"
                                        name="password"
                                        value={password}
                                        className  ="app-form-input"
                                        labelWidth={70}
                                        onChange={this.handleInputchange}
                                    />
                                </FormControl>
                            </Grid>
                         </Grid>
                         <p style={{color:'green', textAlign:'center', marginTop:8, width:'100%'}}>
                            Texto incluído automaticamente nas observações do anúncio no momento do cadastro.</p>
                        </div>
    
                        <Grid container spacing={1} style={{padding:"1em 0"}}>
                            <Grid item xs={6}>
                                <Button type="button" fullWidth className="app-form-send" onClick={this.sendData}>ENVIAR</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button 
                                    type="button" 
                                    style={{width:'100%', margin:0}} 
                                    className="app-form-send app-form-ins-cancel" 
                                    onClick={this.closeForm}
                                >CANCELAR</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Backdrop>
            }
            
            <Snackbar
                open={message != null && message.length>0} 
                autoHideDuration={4000} 
                onClose={this.clearMessage}
                TransitionComponent={this.mySlide}
            />
         </>
        )
      }
}
