import React, { Component } from 'react'
import { Backdrop, Container, Button,
    Snackbar, Slide, FormControl, 
    Grid, InputLabel, OutlinedInput, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Axios from 'axios'
import NumberFormat from 'react-number-format'

import { getLocalUser, host, waitBackDrop } from '../../assets/constants'
import DialogTitle from '../DialogTitle'
import { decodeToken } from '../../assets/auth'

import TextMask from 'react-text-mask' 

export default class ContactMessage extends Component {
    state = {
        open: true,
        text: "",
        phone: "",
        message: null,
        severity: "error",
        error:{},
        wait: false
      }
    
    componentDidMount(){
        const user = getLocalUser(),
            shop = JSON.parse(decodeToken(localStorage.getItem("shop"), true)),
            phone = (shop && shop.phones ? shop.phones[0].telefone : "").trim().replace(/[^0-9]/g, '');
            
        this.setState({user, shop, phone})
        
      }
    
    sendNewPassword = () => {
        const {user, text, phone, shop} = this.state,
            body = {
                mail: shop.proposta,
                nome: `${user.nome} ${user.sobre}`,
                loja: shop.nome + ` (${shop._id})`,
                tel: phone,
                msg: text.trim()
            }
        
        if(text.trim().length <3 ){
            this.setState({message:"Digite uma mensagem válida!", error:{text:true}})
            return
        }
    
    
        this.setState({wait:true, message:null, error:{}}, async ()=>{
            Axios
                .post(`${host}/shops/mail/send`, body)
                .then(({data})=>{
                    this.setState({message: "Mensagem enviada! Aguarde resposta.", severity: "success", wait:false})
                })
                .catch(({response}) =>{ 
                    // if (response && response.status === 401) { message="Confira seu usuário/senha." }
                    this.setState({message: response.data.msg || response.data.message, wait:false, error:{text:true}})
                })
        })
            
      }
    
    closeForm = () => { 
        this.props.onClose()
      }
    
    handleInputchange = e => {
        let {name, value} = e.target;
        
        this.setState({[name]:value})
     }
    
    clearMessage = () => {
        const {severity} = this.state
        this.setState({message:"", severity:"error"}, ()=>{
            if(severity==="success")
                this.closeForm()
        })
     }
    
    mySlide = (props) => <Slide {...props} direction="down"><Alert severity={this.state.severity} onClose={this.clearMessage}>{this.state.message}</Alert></Slide>
     

    TextMaskPhone = (props) => { 
        const { inputRef, ...other } = props;
        return  <NumberFormat format={"(##) # ####-####"} {...other}/> 
    }
    render() {
        const {open, text, phone, user, message, error, wait, severity} = this.state
        
        if(!open || !user)
            return <></>
            
        return (
         <> 
            {
              wait ? waitBackDrop(wait) :
                <Backdrop open={true} className="app-loading" >
                    <Container maxWidth="sm" style={{backgroundColor:"#FFFFFFdd", borderRadius:"5px", display: severity==="success" ? 'none' : null}}>
                        <DialogTitle text1="ENVIAR" text2="MENSAGEM" />
                        
                        <form noValidate className="app-form app-form-login" style={{maxWidth:'none'}}>
                         <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <FormControl className="" variant="filled" fullWidth>
                                    <InputLabel htmlFor="passOld">Mensagem</InputLabel>
                                    <OutlinedInput
                                        error={error.text}
                                        fullWidth
                                        type='text'
                                        autoFocus={true}
                                        id="text"
                                        name="text"
                                        value={text}
                                        className  ="app-form-input"
                                        labelWidth={70}
                                        onChange={this.handleInputchange}
                                        multiline={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel htmlFor="phone">WhatsApp para contato</InputLabel>
                                    <TextField
                                        fullWidth={true}
                                        error={error.phone}
                                        id="phone"
                                        name="phone"
                                        value={phone}
                                        onChange={this.handleInputchange}
                                        variant="outlined"
                                        style={{background:'#ffffff'}}
                                        InputProps={{
                                            inputComponent: this.TextMaskPhone,
                                        }}
                                    />
                                    <OutlinedInput value={phone} style={{display:'none'}}  /> {/* mantido para ocorrer a retração do InputLabel */}
                                </FormControl>
                            </Grid>
                         </Grid>
                        </form>
    
                        <Grid container spacing={1} style={{padding:"1em 0"}}>
                            <Grid item xs={6}>
                                <Button type="button" fullWidth className="app-form-send" onClick={this.sendNewPassword}>ENVIAR</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button 
                                    type="button" 
                                    style={{width:'100%', margin:0}} 
                                    className="app-form-send app-form-ins-cancel" 
                                    onClick={this.closeForm}
                                >CANCELAR</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Backdrop>
            }
            
            <Snackbar
                open={message != null && message.length>0} 
                autoHideDuration={4000} 
                onClose={this.clearMessage}
                TransitionComponent={this.mySlide}
            />
         </>
        )
      }
}
