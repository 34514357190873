import React, { Suspense }  from 'react'
import Axios                from 'axios'
import {host}           from './assets/constants'
import {
    getIp,
    setCookie,
    setMat,
    getCookie,
    getMat  }               from './assets/functions'
import {
  Switch, Route,
  BrowserRouter as Router } from "react-router-dom"

import './app.scss'

import Head                 from './login/Head'
import { decodeToken, userLogout } from './assets/auth'
//import { SubscriptionsOutlined } from '@material-ui/icons'
//import Footer               from './externo/Footer'
//import Home                 from './externo/Home'
const 
    Login       = React.lazy(() => import('./central/Login')),
    AdsList     = React.lazy(() => import('./central/AdsList')),
    AdInsert    = React.lazy(() => import('./central/AdInsert')),
    AdUpdate    = React.lazy(() => import('./central/AdUpdate')),
    AdPhotos    = React.lazy(() => import('./central/AdPhotos')),
    integration = React.lazy(() => import('./central/integration'));


Axios.defaults.withCredentials = true

export default class App extends React.Component{
    state = {
        user        : [],
        shops       : [],
        marks       : [],
        shop        : {},
        redirect    : "",
        showFooter  : window.location.path!=='/' ? true : false,
        location    : {  } //city:"", ip:"", lat:0, lng: 0
    }

    getIpLocation = async (ip) => {
        const   {data}                  = await Axios.post(`${host}/api/geolocal/${ip}`),
         {  geoplugin_city,
            geoplugin_regionCode,
            geoplugin_latitude,
            geoplugin_longitude }       = data;
            
        if(geoplugin_latitude && geoplugin_longitude){ 
            const city = geoplugin_city+'/'+geoplugin_regionCode,
                   lat = geoplugin_latitude * 1,
                   lng = geoplugin_longitude * 1;

            setCookie("pos",setMat([lat, lng, city, ip]),30)
            this.setLocation({ip, city, lat, lng})

            //console.log("Loction", {ip, city, lat, lng })
        }
    }

    setLocation = (location) =>{ this.setState({location}) }

    changeLocation = obj => {
        const location = {...this.state.location, ...obj},
            {lat, lng, city, ip} = location;
        //console.log(location)
        setCookie("pos",setMat([lat, lng, city, ip]),30)
        this.setLocation({ip, city, lat, lng})
    }
    
    handleShowFooter = () => {
        //this.setState({showFooter:true})
    }

    start = () => {
        let token   =  localStorage.getItem("token"),
            user    =  localStorage.getItem("user"),
            shops   =  localStorage.getItem("shops");

        if(token && user && shops){
            token = decodeToken(token, true)
            Axios
                .get(`${host}/users/auth`,{ headers: {'x-access-token': token }})
                .then(r=>{ 
                    this.setUserShops(JSON.parse(decodeToken(user, true)), JSON.parse(decodeToken(shops, true)))
                })
                .catch(()=>{ userLogout() })
        }
    }

    componentDidMount(){
        this.start()
    }

    setUser = user => this.setState({user})

    setUserShops = (user, shops) => this.setState({user, shops})

    getLocation = () => this.state.location

    WaitingComponent(Component, data) {
        return props => (
            <Suspense fallback={<div style={{minHeight:"750px"}}></div>}>
                <Component {...props} data={data}/>
            </Suspense>
        );
    }
    
    render(){
        const {marks, showFooter, location, user, shops, redirect} = this.state 
        
        if(redirect.length)
            return <React to={redirect} /> 

        return(
            <Router>
                {/* <Suspense fallback={<></>}> */}
                    <Head funcSetUserShops={this.setUserShops} funcSetLocation={this.setLocation} user={user} />
                {/* </Suspense> */}
                
                <Switch>
                    <Route path="/anuncio/editar/:revenda/:id" component={this.WaitingComponent(AdUpdate, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/anuncio/fotos/:revenda/:id" component={this.WaitingComponent(AdPhotos, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/anuncio/inserir/:revenda" component={this.WaitingComponent(AdInsert, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    {/* <Route path="/anuncios/:revenda/integracao/:action" component={this.WaitingComponent(integration, {user, shops, funcSetUserShops:this.setUserShops})}/> */}
                    <Route path="/anuncios/:revenda" component={this.WaitingComponent(AdsList, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/sair" component={this.WaitingComponent(Login, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/" component={this.WaitingComponent(Login, {user, shops, funcSetUserShops:this.setUserShops})}/>
                </Switch>

                <Suspense fallback={<></>}>
                    {/* <Footer marks={marks} show={showFooter}/> */}
                </Suspense>
            </Router>
        )
    }
}
