import Axios from 'axios'
import { Backdrop, Container, Button,
         Snackbar, Slide, FormControl, 
         Grid, InputLabel, OutlinedInput, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { StoreOutlined, WhatsApp } from '@material-ui/icons'
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { decodeToken } from '../../assets/auth'
import { getLocalShops, getLocalToken, getLocalUser, host, waitBackDrop } from '../../assets/constants'
import { isValidEmail, isValidPhone } from '../../assets/functions'
import DialogTitle from '../../central/DialogTitle'

export default class ChangeShopPhones extends Component {
  state = {
    open: true,
    phones: [],
    password:"",
    user: null,
    message: null,
    severity: "error",
    error:{},
    wait: false
  }

  componentDidMount(){
    const
        user    = getLocalUser(),
        shopId  = localStorage.getItem("shopId"),
        shop    = getLocalShops("shops").filter(s => s._id===shopId),
        {phones} =  shop[0];

    this.setState({user, phones, shopId})
    // console.log(shop[0])
  }

  sendData = () => {
    let {user, phones, password:pass, shopId:id} = this.state,
        {mail} = user;
    
    if(phones.length){
        let error = false,
            status = {};
        phones = phones.filter((p,i) => {
            if(isValidPhone(p.telefone)){
                return true
            }else if(p.telefone!==''){
                status = {message:"Telefone inválido", error:{[i]:{telefone:true}}}
                error = true
            }
            return false
        })
        if(error){
            this.setState(status)
            return
        } 
    }

    if(pass.length===0){
        this.setState({message:"Informe a senha de acesso!", error:{password:true}})
        return
    }

    this.setState({wait:true, message:null, error:{}}, async ()=>{
        const token = getLocalToken()
        Axios
            .put(`${host}/shops/${id}/update`,{phones},{headers:{'x-access-token': token, data: JSON.stringify({mail, pass})}})
            .then(({data})=>{
                if(data && data.success){
                    this.setState({message: "Atualização Efetuada!", severity: "success", wait:false}, ()=>{
                        let shops = getLocalShops("shops")
                        shops.forEach((s,i) => {
                            if(s._id===id){
                                shops[i]={...s, phones}
                            }
                        });
                        
                        localStorage.setItem("shops", decodeToken(JSON.stringify(shops)))
                    })
                }else{
                    this.setState({message: data.message, wait:false, error:{passOld:true}})
                }
            })
            .catch(({response}) =>{ 
                // if (response && response.status === 401) { message="Confira seu usuário/senha." }
                this.setState({message: response.data.msg || response.data.message, wait:false, error:{passOld:true}})
            })
    })
        
  }

  closeForm = () => { 
    this.props.onClose()
  }

  handleInputchange = e => {
    const {name, value} = e.target;
    this.setState({[name]:value})
    //console.log(name,value)
 }

 clearMessage = () => {
    const {severity} = this.state
    this.setState({message:"", severity:"error"}, ()=>{
        if(severity==="success")
            this.closeForm()
    })
 }

 mySlide = (props) => <Slide {...props} direction="down"><Alert severity={this.state.severity} onClose={this.clearMessage}>{this.state.message}</Alert></Slide>
    
 TextMaskPhone = (props) => { 
    const { inputRef, ...other } = props;
    let fixo = true

    if(other.value.replace(/[^0-9]/g,'').length>10)
        fixo = false

    return  <NumberFormat format={fixo ? "(##) ####-#####" : "(##) # ####-####"} {...other}/>
 }

 handleInputchange = e => {
    let {name, value} = e.target,
        {phones} = this.state;

    name = name.split(".")
    
    if(name.length>1){
        phones[name[1]*1][name[0]]= value
        this.setState({phones})
    }else{
        this.setState({[name[0]]:value})
    }
 }

 getPhoneList = () => {
    let {phones, password, error} = this.state,
        add = true;

    if(phones.length){
        phones.forEach( p => {
            if(p.telefone.trim().length===0)
                add = false
        })
    }

    add && phones.push({telefone:""})
    
    return (
     <Grid container spacing={1} >
        {phones.map( (p,i) => (
            <Grid container item spacing={1} key={i}>
                <Grid item xs={6}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel htmlFor="phone">Número</InputLabel>
                        <TextField
                            fullWidth={true}
                            error={error[i] && error[i].telefone}
                            id={"telefone."+i}
                            name={"telefone."+i}
                            value={p.telefone}
                            onChange={this.handleInputchange}
                            variant="outlined"
                            style={{background:'#ffffff'}}
                            InputProps={{
                                inputComponent: this.TextMaskPhone,
                            }}
                        />
                        <WhatsApp 
                            fontSize='large' 
                            onClick={()=>{this.handleInputchange({target:{name:"whatsapp."+i, value:p.whatsapp ? false : true }})}}
                            style={{color:p.whatsapp ? 'green' : 'lightgray', position:'absolute', top:10, right:2, cursor:'pointer'}}
                        />
                        <OutlinedInput value={p.telefone} style={{display:'none'}}  /> {/* mantido para ocorrer a retração do InputLabel */}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl className="" variant="filled" fullWidth>
                        <InputLabel htmlFor="lastname">Descrição</InputLabel>
                        <OutlinedInput
                            //error={error.contato}
                            fullWidth
                            type='text'
                            // autoFocus={mail.length ? true : false}
                            id={"nome."+i}
                            name={"nome."+i}
                            value={p.nome}
                            className  ="app-form-input"
                            labelWidth={70}
                            onChange={this.handleInputchange}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        ))}
        <Grid item xs={12}>
            <FormControl className="" variant="filled" fullWidth>
                <InputLabel htmlFor="password">Senha de Acesso</InputLabel>
                <OutlinedInput
                    error={error.password}
                    fullWidth
                    type='password'
                    // autoFocus={mail.length ? true : false}
                    id="password"
                    name="password"
                    value={password}
                    className  ="app-form-input"
                    labelWidth={70}
                    onChange={this.handleInputchange}
                />
            </FormControl>
        </Grid>
        <p style={{color:'green', textAlign:'center', marginTop:8, width:'100%'}}>Para excluir um telefone, deixe o número em branco.</p>
     </Grid>
    )
 }

 render() {
    const {open, phones, password, user, message, error, wait, severity} = this.state
    
    if(!open || !user)
        return <></>
//onClick={()=>{this.setState({open:false})}}
    return (
     <> 
        {
          wait ? waitBackDrop(wait) :
            <Backdrop open={true} className="app-loading" >
                <Container maxWidth="sm" style={{backgroundColor:"#FFFFFFdd", borderRadius:"5px", display: severity==="success" ? 'none' : null}}>
                    
                    <DialogTitle text1="ALTERAÇÃO" text2="DE TELEFONES" icon={<StoreOutlined />}/>
                    
                    <div noValidate className="app-form app-form-login" style={{maxWidth:'none'}}>
                        {this.getPhoneList()}
                    </div>

                    <Grid container spacing={1} style={{padding:"1em 0"}}>
                        <Grid item xs={6}>
                            <Button type="button" fullWidth className="app-form-send" onClick={this.sendData}>ALTERAR</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                type="button" 
                                style={{width:'100%', margin:0}} 
                                className="app-form-send app-form-ins-cancel" 
                                onClick={this.closeForm}
                            >CANCELAR</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Backdrop>
        }
        
        <Snackbar
            open={message != null && message.length>0} 
            autoHideDuration={4000} 
            onClose={this.clearMessage}
            TransitionComponent={this.mySlide}
        />
     </>
    )
  }
}
