import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

export default class DialogTitle extends Component {
  render() {
    const {style, text1, color1, text2, color2, icon} = this.props,
        styleDefault = {display:'flex', alignItems:'center', padding:"1em 0px 0.5em", fontSize:"1.2em", fontWeight:'bold', borderBottom:"2px solid lightgray", marginBottom:"1.2em"};
    
    return (
        <Grid style={{...styleDefault, ...style}}>
            <span style={{color:color1, display: icon ? null : 'none'}}>{icon}&nbsp;</span>
            <span style={{color:color1}}>{text1}</span>&nbsp;
            <span style={{color:color2}}>{text2}</span>
        </Grid>
    )
  }
}


DialogTitle.propTypes = {
    style: PropTypes.object,
    text1: PropTypes.string,
    color1: PropTypes.string,
    text2: PropTypes.string,
    color2: PropTypes.string,
    icon: PropTypes.object
};

DialogTitle.defaultProps = {
    style: {},
    text1: "TEXTO DO",
    text2: "TÍTULO",
    color1: "gray",
    color2: "#111",
    icon: null
};